import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'

import SEO from '../components/seo'
import StyledLink from '../components/StyledLink'
import Layout from '../components/layout'
import Text from '../components/Text'
import H1 from '../components/H1'
import HeroBG from '../components/HeroBG'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'

import StyledInput from '../styles/StyledInput'

export default function Connect() {
	const connectPageQuery = useStaticQuery(graphql`
		query connectPageQuery {
			connectPage: wordpressPage(title: { eq: "Contact Us" }) {
				title
				acf {
					hero_image {
						localFile {
							childImageSharp {
								fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	`)

	const acf = connectPageQuery.connectPage.acf

	return (
		<Layout>
			<SEO title={connectPageQuery.connectPage.title} keywords={[ '' ]} />
			<HeroBG imageURL={acf.hero_image.localFile.childImageSharp.fluid} />

			<ThemeProvider theme={styles.palette.blue}>
				<div className="container text-center">
					<H1>{connectPageQuery.connectPage.title}</H1>
				</div>
			</ThemeProvider>
			<MainContentWrap>
				<CustomContainer>
					<ContentSection>
						<Text h3 className="text-center" css={css`margin-bottom: ${styles.margin.mid};`}>
							Message Us
						</Text>
						<form
							name="contact"
							method="post"
							data-netlify="true"
							data-netlify-honeypot="bot-field"
							css={css`
								display: flex;
								flex-direction: column;
								align-items: flex-start;

								& > * {
									margin-bottom: ${styles.margin.small};
								}
							`}
						>
							{/* including this field because it is what netlify requires to process forms with SSG's apparently */}
							<input type="hidden" name="form-name" value="contact" />

							<input
								type="text"
								name="firstName"
								id="firstName"
								placeholder={'First Name'}
								css={StyledInput}
							/>
							<input
								type="text"
								name="lastName"
								id="lastName"
								placeholder={'Last Name'}
								css={StyledInput}
							/>
							<input
								type="email"
								name="email"
								id="email"
								placeholder={'Email Address'}
								css={StyledInput}
							/>
							<textarea name="message" id="message" rows="6" placeholder={'Message'} css={StyledInput} />
							<button
								type="submit"
								value="Send Message"
								css={css`
									background: none;
									border: none;
									padding: 0;
								`}
							>
								<StyledLink css={css`margin: 0;`}>Send</StyledLink>
							</button>
						</form>
					</ContentSection>
				</CustomContainer>
			</MainContentWrap>
		</Layout>
	)
}
